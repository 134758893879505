import PropTypes from 'prop-types';
import React from 'react';
import ApiRequest from 'react-spotify-api/dist/ApiRequest/ApiRequest';

function AllPlaylists(props) {
  var url = 'https://api.spotify.com/v1/me/playlists';
  var options = {
    limit: 50
  };

  return React.createElement(ApiRequest, {
    url: url,
    options: options
  }, function (data, loading, error) {
    return props.children(data, loading, error);
  });
}

AllPlaylists.propTypes = {
  id: PropTypes.string,
  children: PropTypes.func.isRequired
};
export default AllPlaylists;