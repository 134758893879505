export function splitByLimit(allTracks, limit) {
  const overall = [];

  let count = 0;
  const totalItems = allTracks.length - 1;

  let newArray = [];

  allTracks.forEach((item, index) => {
    count = count + 1;
    newArray.push(item);

    if (count === limit) {
      overall.push(newArray);
      count = 0;
      newArray = [];
      return;
    }

    if (index === totalItems) {
      overall.push(newArray);
      return;
    }
  });

  return overall;
}
