import React, { useContext, useEffect } from "react";
import { SpotifyApiContext } from "react-spotify-api";
import { DailyMixContext } from "./DailyMixContext";

function DailyMixes() {

  const token = useContext(SpotifyApiContext);
  const setAllDailyMixTrackIds = useContext(DailyMixContext);

  useEffect(() => {
    // Matt
    const dailyMixIds = [`37i9dQZF1E388iYhZlC98y`, `37i9dQZF1E36Q9kySzFNzB`, `37i9dQZF1E37U1AerQnhi0`, `37i9dQZF1E35DxBgiI4b7f`, `37i9dQZF1E38F2iRsdUz6W`, `37i9dQZF1E35jUY5goza2H`];
    
    // Amee
    // const dailyMixIds = [`37i9dQZF1E39Jg7dxX0SoZ`, `37i9dQZF1E38Vbw81bN2Nc`, `37i9dQZF1E36doz62nUaxi`, `37i9dQZF1E36sfH5RwhkZs`, `37i9dQZF1E38WBt2uKDLVj`, `37i9dQZF1E360VHQp7Jtdt`];
    
    const playlistUrls = dailyMixIds.map(
      id => `https://api.spotify.com/v1/playlists/${id}/tracks`
    );
    async function fetchDailyMixTracks() {
      async function fetchData() {
        const dailyMixPromises = playlistUrls.map(dailyMixUrl =>
          fetch(dailyMixUrl, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
        );
  
        const allDailyMixResults = await Promise.all(dailyMixPromises);
        const allDailyMixTracks = await Promise.all(
          allDailyMixResults.map(result => result.json())
        );
        return allDailyMixTracks;
      }
      const dailyMixData = await fetchData();
      const allDailyMixTracks = [];
      dailyMixData.forEach(dailyMix => dailyMix.items.forEach(track => allDailyMixTracks.push(track)));
      const allDailyMixTrackIds = allDailyMixTracks.map(track => track.track.id);
      setAllDailyMixTrackIds(allDailyMixTrackIds);
    }
    fetchDailyMixTracks();
  }, [token, setAllDailyMixTrackIds]);

  return <div>Checking all your Daily Mixes...</div>;
}

export default DailyMixes;
