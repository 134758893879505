import React, { useEffect, useContext } from "react";
import { SpotifyApiContext } from "react-spotify-api";
import { TrackContext } from "./TrackContext";

export function AllPlaylistTracks({ data }) {
  const token = useContext(SpotifyApiContext);
  const setAllTrackIds = useContext(TrackContext);
  
  useEffect(() => {
    async function fetchData() {
      async function fetchAllTrackIds() {
        async function fetchTracksPerPlaylist(trackUrls) {
          const trackPromises = trackUrls.map(trackUrl =>
            fetch(trackUrl, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`
              }
            })
          );
    
          const trackResults = await Promise.all(trackPromises);
          const tracksPerPlaylist = await Promise.all(
            trackResults.map(trackDataResult => trackDataResult.json())
          );
          return tracksPerPlaylist;
        }
    
        const trackUrls = [];
        data.items.forEach(playlist => trackUrls.push(playlist.tracks.href));
        const tracksPerPlaylist = await fetchTracksPerPlaylist(trackUrls);
        
        const allTracks = [];
        tracksPerPlaylist.forEach(playlist => playlist.items.forEach(track => allTracks.push(track)));
        const allTrackIds = allTracks.map(track => track.track.id);
        return allTrackIds;
      }
      const allTrackIds = await fetchAllTrackIds();
      setAllTrackIds(allTrackIds);
    }

    fetchData();

  }, [token, data, setAllTrackIds]);

  return <div>Checking your existing Playlists...</div>;
}
