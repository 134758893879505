import React, { useContext, useEffect } from "react";
import { SpotifyApiContext } from "react-spotify-api";
import { splitByLimit } from "./utils";

export function CreatePlaylist({ checkedDailyMixTrackIds, setNewPlaylist }) {
  const token = useContext(SpotifyApiContext);

  useEffect(() => {
    async function createPlaylistAddTracks() {
      async function createPlaylist() {
        return fetch(`https://api.spotify.com/v1/me/playlists`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            name: "Daily Mix - New Tracks Only",
            description: "",
            public: false
          })
        });
      }
      const newPlaylistResponse = await createPlaylist();
      const newPlaylist = await newPlaylistResponse.json();

      async function addTracks(newPlaylist, tracks) {
        return fetch(`https://api.spotify.com/v1/playlists/${newPlaylist.id}/tracks`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            uris: tracks.map(id => `spotify:track:${id}`)
          })
        });
      }

      const tracksGroupedBy100 = splitByLimit(checkedDailyMixTrackIds, 100);
      const trackPromises = tracksGroupedBy100.map(tracks => addTracks(newPlaylist, tracks));
      await Promise.all(trackPromises);

      setNewPlaylist(newPlaylist);
    }
    createPlaylistAddTracks();
  }, [token, checkedDailyMixTrackIds, setNewPlaylist]);

  return <></>;
}
