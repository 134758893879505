import React, { useContext, useEffect } from "react";
import { SpotifyApiContext } from "react-spotify-api";
import { splitByLimit } from "./utils";

export function DailyMixFilter({
  allTrackIds,
  allDailyMixTrackIds,
  setCheckedDailyMixTrackIds
}) {
  const token = useContext(SpotifyApiContext);

  useEffect(() => {
    const newDailyMixTracks = [];

    async function fetchData() {
      allDailyMixTrackIds.forEach(track =>
        !allTrackIds.includes(track) ? newDailyMixTracks.push(track) : false
      );

      const tracksGroupedByLimit = splitByLimit(newDailyMixTracks, 50);

      async function fetchSavedTracks(tracks) {
        return fetch(
          `https://api.spotify.com/v1/me/tracks/contains?ids=${tracks}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      }

      const trackPromises = tracksGroupedByLimit.map(item =>
        fetchSavedTracks(item)
      );

      const checkedSavedTrackResults = await Promise.all(trackPromises);
      const checkedSavedTracks = await Promise.all(
        checkedSavedTrackResults.map(results => results.json())
      );

      const results = [];
      tracksGroupedByLimit.forEach((array, outerIndex) => {
        array.forEach((item, innerIndex) =>
          checkedSavedTracks[outerIndex][innerIndex] === false
            ? results.push(item)
            : null
        );
      });

      return results;
    }
    fetchData().then(results => setCheckedDailyMixTrackIds(results));
  }, [token, allTrackIds, allDailyMixTrackIds, setCheckedDailyMixTrackIds]);

  return <div>Filtering your Daily Mixes...</div>;
}
