import React, { useState } from "react";
import { SpotifyApiContext } from "react-spotify-api";
import SpotifyLogin from "react-spotify-login";
import AllPlaylists from "./AllPlaylists";
import { AllPlaylistTracks } from "./AllPlaylistTracks";
import "./App.css";
import { CreatePlaylist } from "./CreatePlaylist";
import { DailyMixContext } from "./DailyMixContext";
import DailyMixes from "./DailyMixes";
import { DailyMixFilter } from "./DailyMixFilter";
import { clientId, redirectUri } from "./settings";
import { TrackContext } from "./TrackContext";

function App() {
  const [spotifyToken, setSpotifyToken] = useState(null);
  const onSuccess = response => {
    setSpotifyToken(response.access_token);
  };
  const onFailure = response => console.error(response);
  const [allTrackIds, setAllTrackIds] = useState(null);
  const [allDailyMixTrackIds, setAllDailyMixTrackIds] = useState(null);
  const [checkedDailyMixTrackIds, setCheckedDailyMixTrackIds] = useState(null);
  const [newPlaylist, setNewPlaylist] = useState(null);

  return (
    <div className="App">
      {!spotifyToken && (
        <SpotifyLogin
          clientId={clientId}
          redirectUri={redirectUri}
          scope={`user-library-read, playlist-modify-public, playlist-modify-private`}
          onSuccess={onSuccess}
          onFailure={onFailure}
          className="button"
        />
      )}
      {spotifyToken && (
        <SpotifyApiContext.Provider value={spotifyToken}>
          <TrackContext.Provider value={setAllTrackIds}>
            <AllPlaylists>
              {(data, loading, error) => (
                <>
                  {loading && <div>Loading all your playlists...</div>}
                  {data && <AllPlaylistTracks data={data} />}
                </>
              )}
            </AllPlaylists>
          </TrackContext.Provider>
          <DailyMixContext.Provider value={setAllDailyMixTrackIds}>
            <DailyMixes></DailyMixes>
          </DailyMixContext.Provider>

          {allTrackIds && allDailyMixTrackIds && (
            <DailyMixFilter
              allTrackIds={allTrackIds}
              allDailyMixTrackIds={allDailyMixTrackIds}
              setCheckedDailyMixTrackIds={setCheckedDailyMixTrackIds}
            ></DailyMixFilter>
          )}

          {checkedDailyMixTrackIds && (
            <CreatePlaylist
              checkedDailyMixTrackIds={checkedDailyMixTrackIds}
              setNewPlaylist={setNewPlaylist}
            />
          )}

          {newPlaylist && (
            <a className="button" href={newPlaylist.external_urls.spotify}>Open Playlist</a>
          )}
        </SpotifyApiContext.Provider>
      )}
    </div>
  );
}

export default App;
